import React, { useEffect, useState } from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";

function App() {
  const Receiver = React.lazy(() => import("./pages/Receiver"));
  const HomePage = React.lazy(() => import("./pages/home"));

  return (
    <BrowserRouter>
      <React.Fragment>
        <div id="wrapper">
          <React.Suspense fallback={<p></p>}>
            <Routes>
              <Route path="/" exact element={<HomePage />} />
              <Route path="/success-voucher" exact element={<Receiver />} />
            </Routes>
          </React.Suspense>
        </div>
      </React.Fragment>
    </BrowserRouter>
  );
}

export default App;
